
.mainFont{
    font-family: 'Libre Baskerville', serif;
   
}

.footerFont{
    font-family: 'Libre Baskerville', serif;
    font-size: 0.85em;
}
@media only screen and (max-width: 450px) {
    .footerFont {
      font-size: 0.65em;
    }
    .footerAlign {
      text-align: center;  
    }
  }
.fadeUp{
    -webkit-animation-name: fadeUp; 
    -webkit-animation-duration: 2s;
    animation-name: fadeUp;
    animation-duration: 2s;
}
    @keyframes fadeUp {
        from {
            opacity: 0;
            transform: translate3d(0,40px,0)
        }
        to {
            opacity:1;
            transform: translate3d(0,0,0);
        }
    }
    @-webkit-keyframes fadeUp {
        from {
            opacity: 0; 
            transform: translate3d(0,40px,0)
        }
        to { 
            opacity: 1; 
            transform: translate3d(0,0,0);
        }
    }


.fadeUpButton{
    -webkit-animation-name: fadeUpButton; 
    -webkit-animation-duration: 5s;
    animation-name: fadeUpButton;
    animation-duration: 5s;
}
    @keyframes fadeUpButton {
        from {
            opacity: 0;
            
        }
        to {
            opacity:1;
            
        }
    }
    @-webkit-keyframes fadeUpButton {
        from {
            opacity: 0; 
        
        }
        to { 
            opacity: 1; 
        
        }
    }
    @import "~react-image-gallery/styles/scss/image-gallery.scss";

    .boxShadow{
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }     
    