.galleryPosition{
    position: absolute; 
    width: 100vw;
    left: 50vw;
    top: 5vh;
    text-align: center;
    -webkit-transform: translate(-50%,-50%);
}
.menuSpecial{
    // border: solid 1px rgba(0,0,0,0.5); 
    padding: 7px; 
    border-radius: 10px; 
    position: absolute;
    left: 10px; 
    top: 170px; 
    // -webkit-transform: translate(-50%,-50%);
    text-align: center;
    z-index: 1;
    width: 30vw; 
    background-color: rgba(0,0,0,0.15);
    color: rgb(255, 255, 255);
    text-shadow: 2px 2px 5px #000;

}
.specialSubFont{
    padding: 5px;
    font-size: 0.8em

}

@media only screen and (max-width: 450px) {
 
    // .galleryPosition{
    //     position: absolute; 
    //     text-align: center;
    //     left: 5vw;
    //     top: 5vh;
    // }
    .menuSpecial{
        width: 70vw;
        top: 60%;
    }
    .galleryImage{
        background-size: 'cover';
        min-height: 90vh;
    }

}
