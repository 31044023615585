.galleryPosition{
    position: absolute; 
    left: 72vw;
    top: 50vh;
}

@media only screen and (max-width: 450px) {
 
    .galleryPosition{
        position: absolute; 
        text-align: center;
        left: 15vw;
        top: 5vh;
    }
}
